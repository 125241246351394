<template>
  <section>
    <div v-if="processing" class="d-flex justify-content-center m-4">
      <loader/>
    </div>
    <div v-else-if="bought.length" class="pricing bottommargin" :class="displayType">
      <template v-if="displayType === $options.ORDER_DISPLAY_TYPE.grid">
        <order-card-component
            v-for="item in bought"
            :key="item.id"
            :item="item"
            :currency="channelSettings.currency"
            @order-updated="getOrders"
        />
      </template>
      <order-table-component v-else :item-list="bought" :currency="channelSettings.currency"/>
    </div>
    <slot v-else name="empty"/>
  </section>
</template>

<script>
  import {mapCacheActions} from "vuex-cache";
  import {mapState} from "vuex";
  import {ORDER_DISPLAY_TYPE} from "../../const";
  import OrderCardComponent from "./OrderCardComponent";
  import OrderTableComponent from "./OrderTableComponent";
  import Loader from "../Loader";

  export default {
    name: "OrderComponent",
    components: {Loader, OrderTableComponent, OrderCardComponent},
    ORDER_DISPLAY_TYPE,
    props: {
      status: {
        type: Array,
        required: true,
      },
      displayType: {
        type: String,
        default: ORDER_DISPLAY_TYPE.grid
      }
    },
    data() {
      return {
        bought: [],
        processing: false,
      }
    },
    computed: {
      ...mapState({
        channelSettings: state => state.channel.settings,
      }),
    },
    methods: {
      ...mapCacheActions({getOrdersRequest: 'GET_ORDERS'} ),
      getOrders() {
        this.processing = true;
        Promise.allSettled(
            this.status.map((status) => this.getOrdersRequest({status}))
        )
            .then((ordersList) => {
              const orders = [];
              ordersList.forEach(({value}) => value && orders.push(...value))
              this.bought = orders
            })
            .finally(() => {
              this.processing = false;
            })

      }
    },
    mounted() {
      this.getOrders();
    },
  }
</script>

<style lang="scss" scoped>
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    gap: .5rem;
    padding: 1rem 0;
  }
  .list{
    margin-top: 1rem;
  }
</style>
