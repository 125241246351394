<template>
  <div class="orders">
    <div
      v-for="item in statusedItemList"
      :key="item[0]?.id"
      class="order"
      :class="{
        opened: item[0].id === openedOrdersId,
      }"
    >
      <div class="order_title">
        <div>
          <span class="order_name">{{ item[0]?.name }}</span>
          <div class="labels">
            <span class="active_label" :class="getActiveStatus(item).toLowerCase()">
              {{ getActiveStatus(item) }}
            </span>
            <span
              class="renew_label"
              v-if="isSubscription(item)"
              :class="getRenewStatus(item) ? 'active' : 'inactive'"
            >
              {{
                getRenewStatus(item)
                  ? $options.RENEW_STATUS.active
                  : $options.RENEW_STATUS.cancelled
              }}
            </span>
          </div>
        </div>
        <div class="order_price">${{ item[0]?.price }} {{ currency }}</div>
      </div>
      <template>
        <div class="toggle_transactions" @click="onToggleClick(item[0].id)">
          <single-angle />
        </div>
        <div class="order_transactions">
          <div v-for="transaction in item" :key="transaction.id" class="transaction">
            <div class="date">{{ getDate(transaction) }}</div>
            <span class="price">${{ transaction.price }} {{ currency }}</span>
            <span class="transaction_status" :class="getTransactionStatus(transaction)">{{
              getTransactionStatus(transaction)
            }}</span>
            <div class="status">{{ transaction.statusText }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  ORDER_STATUS,
  ORDER_STATUS_TEXT,
  ORDER_TYPE,
  RENEW_STATUS,
  TRANSACTION_STATUS,
} from "@/const";
import { getNewDateFromString } from "@/utils/helpers";
import SingleAngle from "../../assets/img/singleAngle";

export default {
  name: "OrderTableComponent",
  components: { SingleAngle },
  RENEW_STATUS,
  props: {
    itemList: {
      type: Array,
      required: true,
      default: [],
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      openedOrdersId: null,
    };
  },
  computed: {
    statusedItemList() {
      const grouped = this.addStatusText(
        this.groupByTypeAndSubscriptionsId(this.itemList)
      );
      return [...grouped.purchases, ...Object.values(grouped?.subscriptions)].sort(
        (next, prev) => {
          return (
            getNewDateFromString(prev[0]?.created) -
            getNewDateFromString(next[0]?.created)
          );
        }
      );
    },
  },
  methods: {
    addStatusText(grouped) {
      Object.values(grouped.subscriptions)?.forEach((group) => {
        let createdStatus = true;
        group
          .sort((next, prev) => {
            return next.product?.start_ts - prev.product?.start_ts;
          })
          .forEach((item) => {
            item.statusText = createdStatus
              ? TRANSACTION_STATUS.created
              : TRANSACTION_STATUS.updated;
            createdStatus = createdStatus && item.status === ORDER_STATUS.failed;
          });

        const last = group.findLast((n) => n);
        if (last.status === ORDER_STATUS.refunded) {
          last.statusText = ORDER_STATUS_TEXT.refunded;
        }
        group.reverse();
      });

      grouped.purchases.forEach((item) => {
        item[0].statusText =
          item[0].status === ORDER_STATUS.refunded
            ? ORDER_STATUS_TEXT.refunded
            : ORDER_STATUS_TEXT.paid;
      });
      return grouped;
    },
    groupByTypeAndSubscriptionsId(list) {
      const grouped = {
        purchases: [],
        subscriptions: {},
      };

      return list.reduce((total, next) => {
        if (next.type === ORDER_TYPE.subscription) {
          const id = next.product.order_subscription_id;
          const subscription = total.subscriptions[id] || (total.subscriptions[id] = []);
          subscription.push(next);
        } else {
          total.purchases.push([next]);
        }
        return total;
      }, grouped);
    },
    getDate(item) {
      const { month, day, year, hh } = this.getFormattedDate({
        ts: getNewDateFromString(`${item.created} UTC`) / 1000,
        formatsOptions: {
          month: { month: "short" },
          day: { day: "2-digit" },
          year: { year: "numeric" },
          hh: { hour: "2-digit", hour12: true, minute: "2-digit" },
        },
      });
      return `${month} ${day} ${year}, ${hh}`;
    },
    getActiveStatus(item) {
      if (item[0].status === ORDER_STATUS.refunded) {
        return ORDER_STATUS_TEXT.inactive;
      }
      if (this.isSubscription(item)) {
        const last = item.findLast((n) => n);
        const renewable = last.product?.renewable;
        const isNowAvailable = new Date(last.product?.end_ts * 1000) > new Date();
        return renewable || isNowAvailable
          ? ORDER_STATUS_TEXT.active
          : ORDER_STATUS_TEXT.inactive;
      }
      return ORDER_STATUS_TEXT.active;
    },
    onToggleClick(id) {
      this.openedOrdersId = this.openedOrdersId === id ? null : id;
    },
    isSubscription(item) {
      return item[0]?.type === ORDER_TYPE.subscription;
    },
    getTransactionStatus(transaction) {
      if (transaction.status === ORDER_STATUS.expired) {
        return ORDER_STATUS.paid;
      }
      return transaction.status;
    },
    getRenewStatus(item) {
      return item.findLast((n) => n)?.product?.renewable;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/css/mixins";

.orders {
  --cancalled: #87909f;
  --active: #48c404;
  --refunded: #f0ad4e;
  --failed: #d9534f;

  font-weight: 400;
  text-transform: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.order {
  border: 1px solid var(--cancalled);
  border-radius: 0.3rem;
  overflow: hidden;
  padding: 1rem;
}

.order_title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    @include media-max(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
.labels {
  display: flex;
  gap: 1rem;
}

.order_name {
  font-weight: 700;
  text-transform: uppercase;
}

.active_label,
.renew_label {
  border-radius: 0.3rem;
  overflow: hidden;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  padding: 0.1rem 0.5rem;
  font-size: 0.8rem;

  &.active {
    background-color: var(--active);
  }

  &.inactive {
    background-color: var(--cancalled);
  }
}

.order_price {
  font-weight: 700;
  font-size: 1.1rem;
  white-space: nowrap;
  align-self: flex-start;
  margin-left: 1rem;
}

.transaction_status {
  border-radius: 0.3rem;
  overflow: hidden;
  color: #fff;
  padding: 0.1rem 0.5rem;
  font-size: 0.8rem;
  position: relative;

  &.paid {
    background-color: var(--active);
  }

  &.failed {
    background-color: var(--failed);
  }

  &.refunded {
    background-color: var(--refunded);
    padding-left: 1.2rem;
  }

  &.expired {
    background-color: var(--cancalled);
  }
}

.toggle_transactions {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 0.5rem 0.5rem 0;

  svg {
    width: 0.5rem;
    transform: rotate(-90deg);
    transition: all 0.2s;
  }
}

.order_transactions {
  height: 0;
  overflow: hidden;
  gap: 0.8rem;
  display: flex;
  flex-direction: column;
}

.transaction {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;

  > *:last-child {
    margin-left: auto;
  }
  @include media-max(sm) {
    > *:last-child {
      margin-left: 0;
    }
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 0 0.5rem;
    grid-template-areas:
      "date price"
      "status transaction";
    .date {
      grid-area: date;
    }
    .price {
      grid-area: price;
    }
    .transaction_status {
      grid-area: transaction;
    }
    .status {
      grid-area: status;
    }
  }
}

.opened {
  .order_transactions {
    height: auto;
  }

  svg {
    transform: rotate(90deg);
  }
}
</style>
