<template>
	<div class="pricing-box pr-2 pl-2">
		<div class="pricing-title">
			<h3>{{item.name}}</h3>
			<span v-if="expired" class="label" >{{$t('expired')}}</span>
		</div>
		<div class="pricing-desc">
			<div v-if="isSubscription" class="ts">
				<template v-if="expired">
					<div class="strong">{{$t('Created')}}: {{createdDate}}</div>
				</template>
				<div class="strong">{{datePrefixText}}: {{expiredDate}}</div>
				<div v-if="!expired" class="renew_cancelled_label">{{renewalSubTitle}}</div>
			</div>
			<div class="pricing-price">
				<span class="price-unit">$</span>{{item.base_price}} <span>{{currency}}</span>
			</div>
			<div class="pricing_fee">
				<span>{{$t('service_fee')}}: ${{parseFloat(item.service_fee).toFixed(2)}} {{currency}} </span>
			</div>
		</div>
		<div class="pricing-action">
			<router-link
					v-if="isPaid"
					:to="getLink()" class="btn btn-outline-danger btn-block btn-lg"
			>
				{{ $t('Watch') }}
			</router-link>
			<button-component
				v-if="subscription && !expired"
				@btn-click="unsubscribeHandler"
				:title="renewalTitle"
				:disabled="unsubscribeProcessing || !renewable"
				class="unsubscribe-btn"
			>
				{{$t('Cancel Auto Renew')}}
			</button-component>
		</div>
	</div>
</template>

<script>
  import ButtonComponent from "../base-components/buttons/ButtonComponent";
  import {mapActions, mapMutations} from "vuex";
	import {ORDER_STATUS, ORDER_TYPE} from "../../const";
	import {getNewDateFromString} from "../../utils/helpers";
  export default {
    name: "OrderCardComponent",
    components: {ButtonComponent},
    props: {
      item: {
        type: Object,
        required: true
      },
      currency: {
        type: String,
        required: true
      },
    },
    data(){
      return {
        unsubscribeProcessing: false,
				itemType: this.item.type,
				isSubscription: this.item.type === ORDER_TYPE.subscription,
				expired: this.item.status === ORDER_STATUS.expired,
				isPaid: this.item.status === ORDER_STATUS.paid
      }
    },
    methods: {
      ...mapActions({unsubscribe: 'UNSUBSCRIBE'}),
      ...mapMutations({clearCache: 'CLEAR_CACHE'}),
      getLink() {
        if (this.itemType === ORDER_TYPE.package || this.itemType === ORDER_TYPE.subscription) {
          return {name: 'games'}
        } else {
          return {name: 'watch', params: {id: this.item?.product?.id || this.item?.id}}
        }
      },
      unsubscribeHandler(){
        this.unsubscribeProcessing = true;
        this.unsubscribe({'order_subscription_id': this.item?.product?.order_subscription_id})
	        .finally(() => {
              this.clearCache();
              this.$emit('order-updated')
	        })

      },
			getDate(ts){
				const {month, day, year} = this.getFormattedDate({
					ts,
					formatsOptions: {
						month: {month: 'long'},
						day: {day: '2-digit'},
						year: {year: 'numeric'}
					}
				})
				return `${month} ${day} ${year}`
			}
    },
    computed: {
      renewable(){
        return this.item?.product?.renewable
      },
      subscription() {
        return this.item?.product?.order_subscription_id
      },
      renewalTitle(){
      	if (this.unsubscribeProcessing){
      		return this.$t('auto renewal cancellation processing')
				}
      	if (!this.renewable){
					return this.$t('auto renewal successfully processed')
				}
      	return '';
			},
			datePrefixText(){
      	if (this.expired){
      		return this.$t('expired on')
				}
				return this.renewable? this.$t('Renews'): this.$t('Until')
			},
			renewalSubTitle(){
				return (this.unsubscribeProcessing || !this.renewable)? this.$t('auto renewal cancelled'): ''
			},
			createdDate(){
      	return this.getDate(getNewDateFromString(`${this.item.created} UTC`) / 1000)
			},
			expiredDate(){
      	return this.getDate(this.item.product.end_ts)
			}
    }
  }
</script>

<style scoped lang="scss">
	.label{
		color: #333;
		opacity: .5;
		font-weight: 500;
		display: block;
		height: 1.5rem;
	}
	.pricing-box {
		position: relative;
		border: 1px solid rgba(0, 0, 0, 0.075);
		border-radius: 3px;
		text-align: center;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
		background-color: #F5F5F5;
		min-width: 16rem;
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.pricing-title {
		padding: 15px 0;
		background-color: #F9F9F9;
		border-radius: 3px 3px 0 0;
		text-shadow: 1px 1px 1px #FFF;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
		overflow: hidden;

		h3 {
			margin: 0;
			font-size: 1.25rem;
			letter-spacing: 1px;
			font-weight: bold;
			text-transform: uppercase;
			color: #555;
		}
	}

	.pricing-price {
		justify-content: center;
		position: relative;
		padding: 25px 1rem;
		color: #333;
		font-weight: 300;
		font-size: 4rem;
		line-height: .7;
		display: flex;
		align-items: flex-end;

		span:nth-child(2) {
			font-size: 0.875rem;
		}

	}

	.pricing-price:after {
		content: '';
		position: absolute;
		width: 100px;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		top: auto;
		bottom: 0;
		left: 50%;
		margin-left: -50px;
	}

	.pricing_fee {
		font-weight: 400;
		margin: .5rem 0;
	}

	.pricing-desc {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		.renew_cancelled_label{
			height: 1.1rem;
			font-size: .75rem;
			margin-top: 1rem;
			color: var(--order-renew-cancelled-label)
		}
		.ts {
			column-gap: .8rem;
			font-size: .9rem;
			padding: .7rem;
			.strong {
				font-weight: bold;
			}
		}

	}

	.pricing-action {
		padding: .4rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		flex-grow: 1;
		justify-content: flex-end;
		.btn {
			margin-top: .5rem;
		}
		.unsubscribe-btn{
			padding: .5rem 1rem;
			line-height: 1.5;
			font-size: 1.25rem;
			font-weight: 400;
			background: transparent;
			border: 1px solid var(--settings-buy-button-background);
			color: var(--settings-buy-button-background);
			&:hover{
				box-shadow: none;
				background: var(--settings-buy-button-background);
				color: var(--settings-buy-button-text-color);
			}
			&:disabled,
			&:disabled:hover{
				background: transparent;
				border: 1px solid var(--settings-buy-button-background);
				color: var(--settings-buy-button-background);
				opacity: .4;
			}
		}
	}
</style>